import step0Info from '../../../share/communication/step0-info'
import step1Products from '../../../share/communication/step1-products'
import step2Actions from '../../../share/communication/step2-actions'
import changeHistory from '../../../share/communication/change-history'
import supplierCommunicationService from '../../../../services/supplier/supplier-recall'
import recallService from '../../../../services/provider/recall'
import { getParentOrgType } from '../../../../misc/auth'
import store from '../../../../store'
import router from '../../../../router/paths'
import siteService from '../../../../services/provider/sites'
import userServices from '../../../../services/user'
import organizationService from '../../../../services/provider/organization'
import { ANTICIPATED_DURATION, COMMUNICATION_TYPE, EMPTY_GUID } from '../../../../misc/commons'
import _ from 'lodash'
export const step = [
    { id: '', name: 'Advisory', order: 1 },
    { id: '', name: 'Products', order: 2 },
    { id: '',  name: 'Actions', order: 3 }
]

export default {
    data() {
        return {
            loading: false,
            initialLoading: true,
            isAddProduct: false,
            recallStep: step,
            currentStep: 0,
            communicationId: '',
            communicationType: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            anticipated_duration: ANTICIPATED_DURATION,
            autonotifychecked: false,
            SupplierContactlst: [],
            disruptionMode: localStorage.getItem('disruptionMode'),
            disruptionItem: {
                id: '',
                name: '',
                communicationUID: '',
                type: '',
                communicationDate: '',
                nextExpectedUpdate: '',
                anticipatedDuration: '',
                anticipatedDurationId: null,
                allocationProcess: null,
                classification: '',
                zNumber: '',
                status: '',
                isUrgent: false,
                isCustomizedContent: false,
                markAs: '',
                supplierId: 0,
                alphaCode: '',
                supplierName: '',
                createdBy: '',
                image: null,
                description: '',
                publicId: '',
                riskToHealth: '',
                typeAction: '',
                resources: '',
                categories: [],
                documents: [],
                products: [],
                actions: [],
                meta_data: null
            },
            recallMetaData: {
                recall_types: [],
                recall_categories: [],
                recall_mark_as: [],
                recall_product_fields: [],
                isCustomizedContent: false
            },
            routeFormType: '',
            disruptionEditPathName: router.supp_ProductDisruption_EditItem.name,
            provdisruptionEditPathName: router.prov_ProductDisruption_EditItem.name,
            ProviderDisruptionListPathName: router.prov_ProdDisruption.name,
            changeHistoryModalKey: 0
        }
    },
    components: {
        step0Info,
        step1Products,
        step2Actions,
        changeHistory
    },
    computed: {
        isDisabled() {
            if (!this.disruptionItem || (this.disruptionItem.id && !['DRAFT', 'OPEN', 'NA'].includes(this.disruptionItem.status))) {
                return true
            }
            else {
                return false
            }
        }
    },
    created() {
        if (this.$route.name === this.provdisruptionEditPathName && this.$route.params && this.$route.params.id) {
            this.initialLoading = true
            this.fetchRecallItemDetail(this.$route.params.id).then(res => {
                if (res) {
                    this.routeFormType = 'EDIT'
                    if (this.$route.params.step) {
                        this.currentStep = this.$route.params.step
                    }
                    this.initialLoading = false
                }
            })
        } else {
            this.routeFormType = 'CREATE'
            this.fetchRecallMetaDataFromService()
        }
        this.getUserSiteforRecallMeta()
        this.getAutoNotifySetting()
        this.fetchSupplierContactList()
    },
    methods: {
        fetchSupplierContactList()
        {
            recallService.findSupplierContacts(this.$store.state.orgId).then(resp => {
                if (!resp.error) {
                    this.SupplierContactlst = resp.data.d
                }
            })
        },
        getAutoNotifySetting()
        {
            organizationService.getAutoNotifySetting().then(resp => {
                if (!resp.error) {
                    this.autonotifychecked=resp.data.d.isautonotify
                    if(resp.data.d.isautonotify)
                    {
                        this.fetchSupplierContactList()
                    }
                }
            })
        },
        getUserSiteforRecallMeta()
        {

            var ParentOrgType=getParentOrgType()
            if(ParentOrgType=='PROVIDER' && ( store.state.roleName ==='Recall Coordinator' || store.state.roleName ==='Responder'))
            {
                siteService.getUserSiteforRecallMeta(this.$route.params.id).then(resp=>{
                    if(!resp.error){
                        this.recallMetaData.recall_usersites = resp.data.d
                    }
                })
            }
            if((ParentOrgType=='NOTISPHERE' || ParentOrgType=='PROVIDER') && ( store.state.roleName !='Recall Coordinator' || store.state.roleName !='Responder'))
            {
                userServices.getSitesMeta(this.$store.state.orgId).then(resp=>{
                    if(!resp.error){
                        this.recallMetaData.recall_usersites = resp.data.d
                    }
                })
            }
        },
        fetchRecallItemDetail(id) {
            return new Promise((resolve,) => {
                recallService.getRecallItemDetail(id).then(res => {
                    if (!res.error) {
                        this.disruptionItem = _.cloneDeep(res.data.d)
                        if (!this.disruptionItem.id || this.disruptionItem.id === EMPTY_GUID) {
                            this._showToast('Invalid Advisory ID', { variant: 'danger' })
                            this.backToRecallList()
                        } else {
                            this.fetchRecallMetaDataFromRecallItem(this.disruptionItem)
                            resolve(true)
                        }

                    }
                })
            })

        },
        fetchRecallMetaDataFromRecallItem(item) {
            if (item && item.meta_data) {
                let _lookupValues = item.meta_data.lookup_values
                let _productFields = item.meta_data.product_fields

                let _recall_mark_as = _lookupValues.find(x => x.name === 'Recall Mark As')
                if (_recall_mark_as) {
                    this.recallMetaData.recall_mark_as = _recall_mark_as.list_vals
                    if (this.recallMetaData.recall_mark_as && this.recallMetaData.recall_mark_as.length > 0) {
                        this.disruptionItem.markAs = this.recallMetaData.recall_mark_as[0].value
                    }
                }
                let _recall_categories = _lookupValues.find(x => x.name === 'Recall Categories')
                if (_recall_categories) {
                    this.recallMetaData.recall_categories = _recall_categories.list_vals
                }
                if (_productFields) {
                    this.recallMetaData.recall_product_fields = _productFields
                }
            }
        },
        fetchRecallMetaDataFromService() {
            recallService.getRecallMetaData().then(res => {
                if (!res.error) {
                    let _lookupValues = res.data.d.lookup_values
                    let _recall_mark_as = _lookupValues.find(x => x.name === 'Recall Mark As')
                    if (_recall_mark_as) {
                        this.recallMetaData.recall_mark_as = _recall_mark_as.list_vals
                        if (this.recallMetaData.recall_mark_as && this.recallMetaData.recall_mark_as.length > 0) {
                            this.disruptionItem.markAs = this.recallMetaData.recall_mark_as[0].value
                        }
                    }
                    let _recall_categories = _lookupValues.find(x => x.name === 'Recall Categories')
                    if (_recall_categories) {
                        this.recallMetaData.recall_categories = _recall_categories.list_vals
                    }
                }
            }).finally(() => {
                this.initialLoading = false
            })
        },
        nextStep() {
            if (this.currentStep < 2) {
                this.currentStep += 1
            }
        },
        previousStep() {
            if (this.currentStep > 0) {
                this.currentStep -= 1
            }
        },
        changeStep(step) {
            if (!isNaN(step) && step >= 0 && step <= 3 && this.currentStep !== step) {
                this.currentStep = step
            }
        },
        backToRecallList() {
            this.$router.push({
                name: this.ProviderDisruptionListPathName
            })
        },
        backToViewRecall() {
            if(this.disruptionItem.id){
                this.$router.push({ name: 'prov_ProdDisruption'})
            } else{
                this.backToRecallList()
            }
        },
        exit() {
            if (this.disruptionMode == 'EDIT') {
                this.showChangeHistoryModal()
            } else {
                this.backToViewRecall()
            }
            if (this.disruptionItem.id || !this.disruptionItem.id === EMPTY_GUID) {
                this.updateEvent()
            }
        },
        draft() {
            if (this.disruptionMode == 'EDIT') {
                this.showChangeHistoryModal()
            } else {
                this.backToViewRecall()
            }
            if (this.disruptionItem.id || !this.disruptionItem.id === EMPTY_GUID) {
                this.updateEvent()
            }
        },
        updateEvent() {
            recallService.updateEvent(this.disruptionItem.id)
        },
        showChangeHistoryModal() {
            this.changeHistoryModalKey += 1
            this.$nextTick(() => {
                this.$refs.changeHistoryModal.forceRerender(this.disruptionItem.id)
            })
        },
        onSavedHistory() {
            this.backToViewRecall()
        },

        saveAndGo(goTypeFn, step = null) {
            if (this.currentStep === 0) {
                this.saveStep0Info(step,goTypeFn.name).then(resp => {
                    if (resp) {
                        goTypeFn(step)
                        this.loading = false
                    }
                }).catch(error => {
                    console.log(error)
                    this.loading = false
                })
            } else if (this.currentStep === 1) {
                this.saveStep1Products(goTypeFn.name).then(resp => {
                    if (resp) {
                        goTypeFn(step)
                        this.loading = false
                    }
                }).catch(error => {
                    console.log(error)
                    this.loading = false
                })
            } else if (this.currentStep === 2) {
                this.saveStep2Actions(goTypeFn.name).then(resp => {
                    if (resp) {
                        goTypeFn(step)
                        this.loading = false
                    }
                }).catch(error => {
                    console.log(error)
                    this.loading = false
                })
            } else {
                goTypeFn(step)
            }
        },

        saveStep0Info(step,type) {
            if(step===null)
            {step=1}
            return new Promise((resolve, reject) => {
                if (!this.isDisabled) {
                    this.$refs.step0Info.save(type).then(resp => {
                        if (resp) {
                            this.communicationId= resp

                            if (this.routeFormType === 'CREATE') {
                                this.$router.push({
                                    name: this.provdisruptionEditPathName,
                                    params: { id: resp, step: step }
                                })
                                resolve(true)
                            } else {
                                this.loading = true
                                this.fetchRecallItemDetail(resp).then(res => {
                                    if (res) {
                                        resolve(true)
                                    }
                                })
                            }
                        }
                    }).catch(error => {
                        reject(error)
                    })
                } else {
                    resolve(true)
                }
            })
        },
        saveStep1Products(type) {
            return new Promise((resolve, reject) => {
                if (!this.isDisabled) {
                    this.$refs.step1Products.save(type).then(resp => {
                        if (resp) {
                            this.communicationId= resp
                            this.loading = true
                            this.fetchRecallItemDetail(resp).then(res => {
                                if (res) {
                                    resolve(true)
                                }
                            })
                        }
                    }).catch(error => {
                        reject(error)
                    })
                } else {
                    resolve(true)
                }
            })
        },
        saveStep2Actions(type) {
            return new Promise((resolve, reject) => {
                if (!this.isDisabled) {
                    this.$refs.step2Actions.save(type).then(resp => {
                        if (resp) {
                            this.communicationId= resp
                            this.loading = true
                            this.fetchRecallItemDetail(resp).then(res => {
                                if (res) {
                                    resolve(true)
                                }
                            })
                        }
                    }).catch(error => {
                        reject(error)
                    })
                } else {
                    resolve(true)
                }
            })
        }
    }
}